import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { AppServiceService } from 'src/app/api/app-service.service';

@Component({
  selector: 'app-lang',
  templateUrl: './lang.page.html',
  styleUrls: ['./lang.page.scss'],
})
export class LangPage implements OnInit {

  constructor(public modalController: ModalController, public appService: AppServiceService) {

  }

  ngOnInit() {
  }

  public langSelect(event) {
    console.log(event.target.value);
  }


  public dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss();
  }

}
