import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { AppServiceService } from '../../../../api/app-service.service';
import { IonInput } from '@ionic/angular';
import consts from '../constatnts.json';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

import { HttpClient } from '@angular/common/http';

declare var config: any;

@Component({
  selector: 'app-modal-popover',
  templateUrl: './modal-popover.page.html',
  styleUrls: ['./modal-popover.page.scss'],
})
export class ModalPopoverPage implements OnInit {
  @ViewChild('googleaddressweb', { read: IonInput }) googleaddress: IonInput;
  @ViewChild('crossSelling') crossSelling: any;

  POPOVER_TYPES = consts.POPOVER_TYPES;

  // This will keep records while navigate between components inside popover
  // Used to manage navigateBackward function
  historyStack: any[] = [];

  @Input() name: string;
  @Input() type: string;
  // always send the title as language key
  @Input() title: string;

  // when page click, slug need to be sent
  @Input() slug = null;

  @Input() isOrderProcessing: boolean = false;

  // Dish image preview url for DISH_IMAGE_PREVIEW
  @Input() dishImageURL = '';

  // cross selling component data set
  @Input() crossSellingProducts = [];
  @Input() selectedDishCategory;
  @Input() isFromCrossSelling: boolean;
  isInQuestionDishesPage: boolean = false;
  selectedQuestionCategory: any = null;

  // @Input() justSelectDeliveryAddress: boolean = false;

  // close popup after selecting delivery address
  // @Input() closeAfterSelection: boolean = false;

  isContentLoading: boolean = false;
  isRestaurantsAvailable: boolean = true;

  address = '';

  @Input() htmlContent: string = '';

  // payment id after placing order in checkout
  uniqueId: string = '';

  // reservation success message html content
  reservationSuccess: string = '';

  @Input() selectedBranch: any = null;

  previousAddressData: any = null;
  addressInputChanged: boolean = false;

  public isFromCheckoutToLocationSelect: boolean = false;
  public backFromAddressSelect: boolean = false;

  constructor(
    private modalCtr: ModalController,
    public appService: AppServiceService,
    private router: Router,
    private httpClient: HttpClient,
    public sanitizer: DomSanitizer,
    public translate: TranslateService
  ) { }

  ngOnInit() { }

  ngAfterViewInit() {
    if (this.type === this.POPOVER_TYPES.LOCATION_SELECT.TYPE) {
      setTimeout(async () => {
        await this.appService.initGoogle(this.googleaddress);
        // get existing address from app object each time go to the address select ui
        if (this.type === this.POPOVER_TYPES.LOCATION_SELECT.TYPE) {
          this.address = this.appService.appObject.myAddress && this.appService.appObject.myAddress.name ? this.appService.appObject.myAddress.name : '';
        }
      }, 1000);
    }
  }

  async close() {
    // if user close without choosing an address, previous address data will reassigned
    if (this.historyStack.length && this.historyStack[this.historyStack.length - 1].TYPE === this.POPOVER_TYPES.CHECKOUT.TYPE && this.type === this.POPOVER_TYPES.LOCATION_SELECT.TYPE && this.addressInputChanged) {
      this.appService.appObject.myAddress = { ...this.previousAddressData };
    }
    await this.modalCtr.dismiss();
  }

  /**
   * Will trigger when user click back btn from the view delivery address select
   */
  navigateBackward() {
    if (this.historyStack.length) {
      // if user came back from location select ui to checkout page this will trigger
      if (this.historyStack[this.historyStack.length - 1].TYPE === this.POPOVER_TYPES.CHECKOUT.TYPE && this.type === this.POPOVER_TYPES.LOCATION_SELECT.TYPE) {
        if (this.addressInputChanged) this.appService.appObject.myAddress = { ...this.previousAddressData };
        this.backFromAddressSelect = true;
      }
      // get previous page and navigate
      this.navigateTo(this.historyStack[this.historyStack.length - 1], null, true);

      // remove the last record each time user navigate to previous page
      this.historyStack.splice(this.historyStack.length - 1, 1);
    }
  }

  /**
   * Navigate to another modal content or a page in web version
   * @param location the location to navigate
   * @param extraData extra data set if want to
   * @param isBackwardNavigating check wether user navigating backward (this one use to manage historyStack array)
   */
  navigateTo(location, extraData: any = null, isBackwardNavigating: boolean = false) {
    if (location === 'restaurant-list') {
      // clear error messages
      this.appService.appObject.errors = [];

      // clear my address data when user need all restaurants to select
      let getAllRestaurants = false;
      if (extraData === 'all') {
        getAllRestaurants = true;
      }
      // find restaurants
      this.appService.findNearByRestaurants(false, getAllRestaurants).then(async (response) => {
        // if has selected branch, check it's avialble for selected address to delivery
        // if it is then go to menu page, otherwise display an errror message
        if (this.selectedBranch) {
          let restaurant = this.appService.appObject.restaurants.find((restaurant) => restaurant.id === this.selectedBranch.id) // this.selectedBranch.id
          if (restaurant) {
            await this.appService.getRestaurantBySlug(restaurant.slug).then((response) => {
              this.router.navigate(['/menu']);
              this.close();
            });
          } else {
            this.appService.appObject.errors['address'] = ['selected_restaurant_cannot_delivery_to_selected_address'];
            return;
          }
        }

        if (this.appService.appObject.restaurants && this.appService.appObject.restaurants.length > 1) {
          // keep navigation history to navigate backward (just like in angular routing)
          if (getAllRestaurants) {
            this.historyStack.push(this.POPOVER_TYPES.DELIVERY_TYPE);
          } else {
            this.historyStack.push(this.POPOVER_TYPES.LOCATION_SELECT);
          }

          this.type = this.POPOVER_TYPES.RESTAURANT_SELECT.TYPE;
          // always send the title as language key
          this.title = this.POPOVER_TYPES.RESTAURANT_SELECT.TITLE;
        } else if (this.appService.appObject.restaurants && this.appService.appObject.restaurants.length === 1) {
          if (this.appService.appObject.restaurants[0].slug) this.navigateTo('menu-page', this.appService.appObject.restaurants[0]);
        } else if (!this.appService.appObject.restaurants || !this.appService.appObject.restaurants.length) {
          this.appService.appObject.errors['address'] = ['selected_restaurant_cannot_delivery_to_selected_address'];
        }

      });
    } else if (location === 'menu-page') {
      if (extraData) {
        this.appService.getRestaurantBySlug(extraData.slug).then((response) => {
          this.router.navigate(['/menu']);
          this.close();
        });
      } else {
        this.router.navigate(['/menu']);
        this.close();
      }
    } else if (location && location.TYPE && location.TITLE) {
      // keep navigation history to navigate backward (just like in angular routing)
      // skip recording when user navigate backward with "isBackwardNavigating" condition
      // the second condition will prevent user go back to login form after login in to the user profile
      if (!isBackwardNavigating && !(location.TYPE === this.POPOVER_TYPES.USER_PROFILE.TYPE && this.type === this.POPOVER_TYPES.LOGIN.TYPE)) {
        Object.keys(this.POPOVER_TYPES).map((key) => {
          if (this.POPOVER_TYPES[key].TYPE === this.type) {
            this.historyStack.push(this.POPOVER_TYPES[key]);
          }
        });
      }

      // this will prevent user go back to registration form after successfuly registered and navigate in to the user profile
      // or user came to login page from checkout page and redirected into checkout page after logged in (prevent go back to login ui)
      // or user came to checkout page from login > register , and checkout, then prevent go back to registration page
      // or user came to order summary from checkout page and prevent go back to checkout page from summary page
      // or user came to delivery address select from login page prevent go back to login page
      if ((location.TYPE === this.POPOVER_TYPES.USER_PROFILE.TYPE && (this.type === this.POPOVER_TYPES.REGISTER.TYPE || this.type === this.POPOVER_TYPES.REGISTER_VIA.TYPE))
        || ((this.type === this.POPOVER_TYPES.LOGIN.TYPE
          || this.type === this.POPOVER_TYPES.REGISTER.TYPE
          || this.type === this.POPOVER_TYPES.REGISTER_VIA.TYPE)
          && location.TYPE === this.POPOVER_TYPES.CHECKOUT.TYPE)
        || this.type === this.POPOVER_TYPES.CHECKOUT.TYPE && location.TYPE === this.POPOVER_TYPES.ORDER_SUMMARY.TYPE
        || this.type === this.POPOVER_TYPES.LOGIN.TYPE && location.TYPE === this.POPOVER_TYPES.LOCATION_SELECT.TYPE) {
        this.historyStack = [];
      }

      // get existing address from app object each time go to the address select ui
      if (location.TYPE === this.POPOVER_TYPES.LOCATION_SELECT.TYPE) {
        this.address = this.appService.appObject.myAddress && this.appService.appObject.myAddress.name ? this.appService.appObject.myAddress.name : '';
      }
      // if user navigate to location select from checkout page, keep a record that will return from location select page to checkout page any time
      if (this.type === this.POPOVER_TYPES.CHECKOUT.TYPE && location.TYPE === this.POPOVER_TYPES.LOCATION_SELECT.TYPE) {
        // keep if there is an address already selected
        if (this.appService.appObject.myAddress) this.previousAddressData = { ...this.appService.appObject.myAddress };

        this.isFromCheckoutToLocationSelect = true;
      } else {
        this.isFromCheckoutToLocationSelect = false;
      }

      this.type = location.TYPE;
      this.title = location.TITLE;

      // reset history stack if the current page is login, once user reset password successfuly navigate to login
      // login is a main page so no need to go back to password reset ui
      if (this.type === this.POPOVER_TYPES.LOGIN.TYPE) {
        this.historyStack = [];
      }

      // if next page is for select locations to find restaurants, then initialize google map service
      if (location.TYPE === this.POPOVER_TYPES.LOCATION_SELECT.TYPE) {
        if (navigator.geolocation && !this.appService.appObject.isAddressHave) {
          this.getCurrentLocation();
        } else if (!this.appService.appObject.myAddress || !this.appService.appObject.myAddress.name) {
          this.address = '';
        }

        setTimeout(() => {
          this.appService.initGoogle(this.googleaddress);
        }, 1000);
      }
    }
  }

  getCurrentLocation() {
    this.appService.appObject.isLoading = true;
    navigator.geolocation.getCurrentPosition((position) => {
      const longitude = position.coords.longitude;
      const latitude = position.coords.latitude;


      this.httpClient.get("https://maps.googleapis.com/maps/api/geocode/json?latlng=" + latitude + "," + longitude + "&sensor=true&key=" + config.gkey).toPromise().then((response: any) => {
        // console.log('response : ', response)
        if (response.status === 'OK') {
          let place = null;
          let isAddressValid = false;
          for (let i = 0; i < response.results.length; i++) {
            let validateResult = this.appService.validateAddressComponents(response.results[i].address_components);
            // if place is null, then assign one place object to get formatted address
            place = !place ? response.results[i] : place;

            if (this.appService.isAddressComponentValid(validateResult)) {
              isAddressValid = true;
              place = response.results[i];
              break;
            }
          }

          // console.log('addressValidating : ' , addressValidating);
          if (isAddressValid && place) {
            // console.log('place : ' , place);
            let postal, city, road = '';
            this.address = '';

            for (let i = 0; i < place['address_components'].length; i++) {
              let result = place['address_components'][i];
              if (result['types'][0] == "postal_code") {
                postal = result['short_name'];
              }
              if (result['types'][0] == "locality") {
                city = result['short_name'];
              }
              if (result['types'][0] == "route") {
                road = result['short_name'];
              }

              if (postal && city && road) {
                this.address = road + ', ' + postal + ', ' + city;
              }
            }


            this.appService.appObject.isAddressHave = true;
            // let address_splited = place.formatted_address.split(',');
            // this.address = place.formatted_address;
            // let country = address_splited[address_splited.length - 1];

            // if (country.trim() === 'Germany') {
            //   this.address = this.address.replace(", Germany", "");
            // }

            this.appService.appObject.myAddress.name = this.address;
            // console.log(this.appService.appObject.myAddress.name);
            this.appService.appObject.myAddress.lat = place.geometry.location.lat;
            this.appService.appObject.myAddress.lng = place.geometry.location.lng;
            this.appService.updateAppObject();
            this.appService.appObject.errors = [];
          } else {
            this.address = place.formatted_address;
            this.appService.appObject.isAddressHave = false;
            this.appService.appObject.myAddress.name = '';
            this.appService.appObject.myAddress.lat = '';
            this.appService.appObject.myAddress.lng = '';

            this.appService.updateAppObject();
            this.appService.appObject.errors['address'] = ['address_is_not_valid'];
          }
        }

        this.appService.appObject.isLoading = false;
      }).catch((error) => {
        console.log('google location error : ', error);
        this.appService.appObject.isLoading = false;
      });
    }, (error) => {
      this.appService.appObject.isLoading = false;
      if (error && error.code == error.PERMISSION_DENIED) {
        this.translate.get(['key.please_allow_location_permission_to_get_your_current_location']).toPromise().then((response) => {
          this.appService.showToast(response['key.please_allow_location_permission_to_get_your_current_location'] ? response['key.please_allow_location_permission_to_get_your_current_location'] : 'key.please_allow_location_permission_to_get_your_current_location');
        }).catch((error) => {
          console.log('error : ', error);
          this.appService.showToast('key.please_allow_location_permission_to_get_your_current_location');
        });
      }
    });
  }

  /**
   * This will pass as a callback into login component to trigger once user logged in
   * This also used to pass into registration component as a call back to get trigger once user successfuly registered and logged in
   * and return login operation response data set
   * @param loginResponse after login response object
   */
  async onLoginSuccess(loginResponse) {
    await this.appService.updateAppObject();
    if (this.appService.appObject.isOrderProcess) {
      if (this.appService?.appObject?.selectedDeliveryMethod === 'delivery' && (!this.appService.appObject.myAddress || !this.appService.appObject.myAddress.name
        || !this.appService.appObject.myAddress.lat || !this.appService.appObject.myAddress.lng)) {
        // this.justSelectDeliveryAddress = true;
        this.navigateTo(this.POPOVER_TYPES.LOCATION_SELECT);
      } else {
        // if user came to login page from checkout ui, then redirect into checkout page
        this.navigateTo(this.POPOVER_TYPES.CHECKOUT);
      }
      // make it false once user redirected to the checkout page
      this.appService.appObject.isOrderProcess = false;
    } else {
      this.navigateTo(this.POPOVER_TYPES.USER_PROFILE);
    }
    // console.log('loginResponse : ', loginResponse);
  }

  /**
   * Wll call back when user select create an account option from login page
   */
  onRegisterVia() {
    this.navigateTo(this.POPOVER_TYPES.REGISTER_VIA);
  }

  /**
   * This will call back when user select register with email option in register page
   */
  onRegisterViaEmail() {
    this.navigateTo(this.POPOVER_TYPES.REGISTER);
  }

  /**
   * This will call back when user choose forgot password option in login page
   */
  onForgotPassword() {
    this.navigateTo(this.POPOVER_TYPES.FORGOT_PASSWORD);
  }

  /**
   * This will call back when user reset password successfuly
   */
  onPasswordResetSuccess() {
    this.navigateTo(this.POPOVER_TYPES.LOGIN);
  }

  /**
   * This will navigate into dynamic pages slug is required
   * used for pnp and tnc
   * @param slug page slug
   */
  onNavigatePages(slug) {
    let pages = this.appService && this.appService.appObject && this.appService.appObject.settings && this.appService.appObject.settings.pages ? this.appService.appObject.settings.pages : [];
    let page = pages.find((page) => page.slug === slug);
    if (page) {
      this.slug = page.slug;
      this.navigateTo({ TYPE: this.POPOVER_TYPES.PAGES.TYPE, TITLE: page.name });
    }
  }

  /**
   * On user change the delivery address from checkout page
   */
  onChangeDeliveryAddress() {
    this.navigateTo(this.POPOVER_TYPES.LOCATION_SELECT);
  }

  /**
   * Will trigger when user checkout as a guest user
   * @param paymentId payment id of the placed order
   */
  onOrderPlaced(paymentId, deliveryType) {
    if (paymentId) {
      this.uniqueId = paymentId;
      this.navigateTo({ TYPE: this.POPOVER_TYPES.ORDER_SUMMARY.TYPE, TITLE: 'key.' + deliveryType })
    }
  }

  /**
   * Will trigger when user select ordering type
   * @param orderType selected ordering type
   */
  selectOrderType(orderType) {
    this.appService.appObject.selectedDeliveryMethod = orderType;
    this.appService.updateAppObject();
    if (!this.selectedBranch) this.appService.calculateCartAmounts();
  }

  /**
   * Will trigger when user go to question page opening after adding item into cart
   * @param categoryData selected category data
   */
  openQuestionDishPage(categoryData) {
    if (categoryData) this.selectedQuestionCategory = categoryData;
  }

  /**
   * Will trigger when user click back button from cross selling dishes list page
   */
  navigateBackwardFromQuestionPage() {
    this.selectedQuestionCategory = null;
    this.crossSelling.navigateBackInWebVersion();
  }

  /**
   * Will trigger when user successfully created a reservation
   * @param response response from table reservation form
   */
  onReservationSuccess(response) {
    if (response) {
      this.reservationSuccess = response;
      this.title = 'key.reservation_request_submitted';

      // change the hight of the popup container to get nicer look of the success message
      let element = document.getElementsByClassName('modal-wrapper');
      // console.log('element : ', element);
      if (element && element[0]) {
        element[0].classList.add('height-300-px');
      }
    }
  }

  /**
   * location select page action button
   */
  addressSelectAction() {
    if (this.isFromCheckoutToLocationSelect) {
      this.addressInputChanged = false;
      this.appService.findNearByRestaurants('do-not-redirect', false).then(async (response) => {
        let selectedBranch = this.appService.appObject.restaurants.find((restaurant) => restaurant.id === this.appService.appObject.selectedRestaurantDetails.id);
        if (selectedBranch) {
          // this.address
          await this.appService.calculateCartAmounts();
          this.navigateBackward();
        } else {
          this.appService.clearAddress('selected_restaurant_cannot_delivery_to_selected_address');
        }
      }).catch((error) => {
        console.log('error : ', error);
      });
    } else {
      this.navigateTo('restaurant-list');
    }
  }
}
